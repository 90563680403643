// @container (max-width: 1400px){
//     .content_description{

//         &:nth-child(2){
//             flex-direction:column
//         }
//     }
// }

@media screen and (min-width:1919px) {
    body {
        section {
            .parent_container {
                .main {
                    .latest_container {
                        .last_night {
                            .flexbox {
                                .latest_project_details {
                                    width: max(200px, 30%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    [data-scroll-container] {
        scroll-behavior: auto;
        /* or any other value suitable for your needs */
    }

   

    body {
        overflow: auto;

        .gallery_container{
            width: 100%;
            height: 100dvh;

            .picture_holder{
                width: 100%;
                img{
                    width: 100%;
                }
            }

            .arrow_holder{
                position: relative;
                z-index: 3;
            }

            
        }

        //nav
        nav {
            ul {
                flex-direction: row-reverse;

                li:nth-child(1) {
                    border-right: none;
                }

                li:nth-child(3) {
                    border: none;

                    div:first-child {
                        padding: 20px;
                    }

                    div:nth-child(2) {
                        display: none;
                    }
                }

                li:nth-child(2) {
                    width: 90px;
                    display: grid;
                    place-items: center;

                    img {
                        object-position: center;
                    }
                }
            }

            .menu_container {
                .container {
                    .flexbox {
                        flex-direction: column;

                        :is(.flex1, .flex2) {
                            width: 100%;
                        }

                        .flex1 {

                            div{
                                h1{
                                    font-weight: 400;
                                    font-size: 1.2rem;
                                }

                                p{
                                    font-weight: 400 !important;
                                }
                            }
                           

                            div:nth-child(1) {
                                margin-top: 10px;
                            }
                            div:nth-child(4) {
                                padding-top: max(50px,6svh);
                            }
                        }

                        .flex2 {
                            display: block;
                            height: fit-content;
                            border-left: 1px solid rgba(128, 128, 128, 0.499);
                            opacity: 0;

                            .content {
                                display: flex;
                                justify-content: space-between;

                                a {
                                    width: 55%;

                                    .featured_gallery {
                                        img {
                                            width: 50%;
                                        }
                                    }
                                }

                                .nav_location {
                                    width: 30%;
                                    margin: 0;
                                    display: grid;
                                    place-items: center;

                                    ol {
                                        padding: 0;

                                        li {
                                            font-size: .9rem;
                                            padding-top: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .iframe_container{
            width: 100%;
        }

        section {
            .parent_container {
                width: 100%;
                overflow-x: hidden;


                //banner
                .banner {
                    .banner_content {
                        margin-bottom: 25px;

                        .banner_mute {
                            display: none;
                        }

                        div:nth-child(2) {
                            width: max(270px, 65%);

                            h1,
                            p {
                                text-align: left;


                            }

                            p {
                                font-size: .7rem;

                            }
                        }

                        div:nth-child(3) {
                            font-size: .8rem;
                        }
                    }
                }

                //main
                .main {
                    .latest_container {
                        margin-top: 25px;

                        .last_night {
                            .flexbox {
                                display: block;

                                .latest_project_details {
                                    width: 100%;
                                    height: max(520px, 30vh);


                                    .latest_description {
                                        bottom: 0;
                                        transform: translateY(0);

                                        .content_trey {
                                            .content_description {
                                                &:first-child {
                                                    align-items: center;
                                                }

                                                p {
                                                    color: rgb(0, 0, 0);
                                                    font-size: .9rem;
                                                    margin-top: 30px;
                                                    font-weight: 400;
                                                }

                                                .mobile_caption {
                                                    display: block;

                                                    p {
                                                        color: black;
                                                    }

                                                    h1 {
                                                        margin-bottom: 10px;
                                                    }

                                                    h5 {
                                                        font-size: 1.2rem;
                                                        line-height: 1.5;
                                                        margin-top: 20px;
                                                        //margin: 0;
                                                        font-weight:700;
                                                    }

                                                }

                                            }
                                        }
                                    }
                                }

                                .poster1 {
                                    width: 100%;

                                    .flex2_trey {
                                        margin-top: 25px;
                                    }
                                }

                                .content {
                                    display: none;

                                }
                            }
                        }

                        .latest_project {
                            display: none;
                        }
                    }
                }

                //portfolio/works
                .portfolio_section {
                    .caption {
                        h1 {
                            margin-top: 80px;
                        }
                    }

                    .portfolio_container {
                        margin: 10px auto;
                        height: max(150em, 260dvh);

                        .portfolio_heading {
                            h1 {
                                width: 100%;
                                font-size: clamp(1.5rem, 2.5vw, 4.5rem);
                            }
                        }

                        a {
                            .cards {
                                width: 80%;

                                &:nth-child(2) {
                                    width: 60%;
                                    top: 10%;
                                    right: 0%;
                                    transform: translateY(-0%, -5%);
                                }

                                &:nth-child(3) {
                                    width: 65%;
                                    top: 40%;
                                    left: 23%;
                                    transform: translateY(-3%, -25%);
                                }

                                p {
                                    font-size: .7rem !important;
                                }

                                &:nth-child(4) {
                                    width: 55%;
                                    top: 80%;
                                    right: 3%;
                                    transform: translateY(-3%, -60%);
                                }

                                &:nth-child(5) {
                                    width: 55%;
                                    top: 90%;
                                    right: 3%;
                                    transform: translateY(-3%, -80%);
                                }


                            }
                        }


                        a {
                            .card1 {
                                top: 8%;
                                transform: translateY(-8%);
                            }

                            .card2 {
                                top: 28% !important;
                                transform: translateY(-28%) !important;
                            }


                        }

                        a:nth-child(3) {
                            .cards {
                                .cover {
                                    width: 365px;
                                    height: 274px;
                                }
                            }
                        }

                        .cards:nth-child(4){
                            .cover{
                                img{
                                    width: 74%;
                                }
                            }
                        }

                        .cards:nth-child(5){
                            .cover{
                                img{
                                    width: 76%;
                                }
                            }
                        }


                        #card3 {
                            top: 63% !important;
                            transform: translateY(-63%) !important;
                            width: 90%;

                            .flex1 {
                                margin: 0;
                            }
                        }

                        #card4 {
                            top: 95% !important;
                            transform: translateY(-95%) !important;
                        }


                    }
                }

                //reels_section
                .reels_section {
                    .reels_container {
                        width: 90%;
                        height: 70vh;
                        align-items: center;

                        .flex1,
                        .flex3 {
                            display: none;
                        }

                        .flex2 {
                            flex-grow: 1;

                            .video_reel {
                                .video_trey {
                                    .reel {
                                        position: relative;


                                        .overlay {
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            display: block;

                                            .details {
                                                position: absolute;
                                                bottom: 15px;
                                                left: 20%;
                                                transform: translate(-20%);

                                                .profile_image {
                                                    width: 20%;

                                                    img {
                                                        width: 100%;
                                                    }
                                                }

                                                p:nth-child(2) {
                                                    font-size: .8rem;
                                                }

                                                p:nth-child(3) {
                                                    font-size: .7rem;
                                                    width: 70%;
                                                }
                                            }

                                            .interactives {
                                                position: absolute;
                                                right: 15px;
                                                bottom: 25px;

                                                .icon:first-child {
                                                    width: 43px;
                                                    margin: 15px 0;

                                                    img {
                                                        width: 100%;
                                                    }
                                                }
                                            }

                                            .reel_heading {
                                                padding: 5%;

                                                p {
                                                    font-size: .7rem;
                                                }

                                                .socials {
                                                    display: flex;

                                                    .social2 {
                                                        width: 42px;

                                                        img {
                                                            width: 100%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                //About Section
                .about_section {
                    .about {
                        width: 70%;

                        .poka_icon {
                            margin: 20px 0;
                        }

                        p {
                            font-size: .9rem;
                        }
                    }
                }

                //Footer

                footer {
                    .contact {
                        display: block;

                        .flex {
                            width: 100%;

                            .contact_img {
                                width: max(130px, 34%);
                            }
                        }
                    }

                    .footer {
                        width: 90%;
                        margin: 0 auto;
                        padding: 50px 0;

                        .footer_content {
                            display: block;

                            .logo,
                            .location,
                            .social_media {
                                margin-top: 50px;

                                p {
                                    margin: 5px 0;
                                    font-size: .9rem;
                                }


                            }

                            .logo {
                                width: 60%;

                                img {
                                    width: 100%;
                                }

                                p {
                                    font-size: .8rem;
                                }
                            }

                            .location {
                                .location_container {
                                    align-items: flex-end;

                                    .link_icon {
                                        width: 15px;

                                        img {
                                            width: 100%;
                                        }
                                    }
                                }
                            }



                            .social_media {
                                ul {
                                    padding: 0;

                                    li {
                                        .link_icon {
                                            width: 15px;

                                            img {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .work_section{
                    .works_flexbox{
                        flex-direction: column;

                        .gradient_background{
                            display: none;
                        }

                        .works_container{
                            width: 100%;

                            .works{
                                
                                //height: max(4280px,30vh);
                                padding-bottom: 80px;
                                .vizualizer_container{
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: flex-start;
                                    height: auto;
                                    padding: 0;
                                    padding-top: 10px;
                                    gap: 10px;

                                    .vizualizer{
                                        width: 95%;
                                     

                                        .viz_img_container{
                                            height: max(230px,29.5vh);
                                            margin-bottom: 20px;
                                        
                                        }

                                        .gallery_img_container{
                                            height: max(160px,20vh);
                                            margin-bottom: 10px;

                                            img{
                                                width: 99%;
                                            }
                                            .glow{
                                                height: inherit !important;
                                            }
                                        }
                                    }

                                    .vizualizer:nth-child(2){
                                        align-items: flex-start;
                                    }
                                }

                                .vizualizer_container:nth-child(1){

                                    padding-top: 100px;
                                }
                                
                            }

                            #works{
                                grid-template-columns: auto auto;

                                .vizualizer_container{
                                    height: auto;
                                }
                               
                            }
                        }

                        #gallery_works_container{
                            width: 95%;
                            margin: 0 auto;
                        }

                        .side_details{
                            width: 100%;
                            display: none;
                        }
                    }
                    
                    .gradient_background{
                        width: 100%;
                    }
                }

            }
        }

        //Reel Mark up styling here...
        .reel_parent {
            width: 100%;
            height: 100dvh;
            border: 2px solid red;
            overflow: auto;

            .reel_trey {
                display: flex;
                flex-direction: column;
                height: max-content;

                .reels {
                    width: 100%;
                    height: 100dvh;
                    background-color: orange;

                    &:nth-child(2) {
                        background-color: orangered;
                    }
                }


            }
        }
    }
}

@media screen and (max-width:390px) {
    body{
        section{
            .parent_container{
                .main{
                    .latest_container{
                        .last_night{
                            .flexbox{
                                .latest_project_details{
                                    height: max(570px, 30vh);
                                }
                            }
                        }
                    }
                }

                .portfolio_section{
                    .portfolio_container{
                        a:nth-child(3){
                            .cards{
                                .cover{
                                    width: 315px;
                                }
                            }
                        }

                        .cards:nth-child(4){
                            .cover{
                                img{
                                    width: 65%;
                                }
                            }
                        }

                        .cards:nth-child(5){
                            .cover{
                                img{
                                    width: 65%;
                                }
                            }
                        }
                    }
                }

               
                
            }
        }
    }
}