@media screen and (width >= 1919px) {
  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details {
    width: max(200px, 30%);
  }
}

@media screen and (width <= 500px) {
  [data-scroll-container] {
    scroll-behavior: auto;
  }

  body {
    overflow: auto;
  }

  body .gallery_container {
    width: 100%;
    height: 100dvh;
  }

  body .gallery_container .picture_holder, body .gallery_container .picture_holder img {
    width: 100%;
  }

  body .gallery_container .arrow_holder {
    z-index: 3;
    position: relative;
  }

  body nav ul {
    flex-direction: row-reverse;
  }

  body nav ul li:first-child {
    border-right: none;
  }

  body nav ul li:nth-child(3) {
    border: none;
  }

  body nav ul li:nth-child(3) div:first-child {
    padding: 20px;
  }

  body nav ul li:nth-child(3) div:nth-child(2) {
    display: none;
  }

  body nav ul li:nth-child(2) {
    place-items: center;
    width: 90px;
    display: grid;
  }

  body nav ul li:nth-child(2) img {
    object-position: center;
  }

  body nav .menu_container .container .flexbox {
    flex-direction: column;
  }

  body nav .menu_container .container .flexbox :is(.flex1, .flex2) {
    width: 100%;
  }

  body nav .menu_container .container .flexbox .flex1 div h1 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  body nav .menu_container .container .flexbox .flex1 div p {
    font-weight: 400 !important;
  }

  body nav .menu_container .container .flexbox .flex1 div:first-child {
    margin-top: 10px;
  }

  body nav .menu_container .container .flexbox .flex1 div:nth-child(4) {
    padding-top: max(50px, 6svh);
  }

  body nav .menu_container .container .flexbox .flex2 {
    opacity: 0;
    border-left: 1px solid #8080807f;
    height: fit-content;
    display: block;
  }

  body nav .menu_container .container .flexbox .flex2 .content {
    justify-content: space-between;
    display: flex;
  }

  body nav .menu_container .container .flexbox .flex2 .content a {
    width: 55%;
  }

  body nav .menu_container .container .flexbox .flex2 .content a .featured_gallery img {
    width: 50%;
  }

  body nav .menu_container .container .flexbox .flex2 .content .nav_location {
    place-items: center;
    width: 30%;
    margin: 0;
    display: grid;
  }

  body nav .menu_container .container .flexbox .flex2 .content .nav_location ol {
    padding: 0;
  }

  body nav .menu_container .container .flexbox .flex2 .content .nav_location ol li {
    padding-top: 8px;
    font-size: .9rem;
  }

  body .iframe_container {
    width: 100%;
  }

  body section .parent_container {
    width: 100%;
    overflow-x: hidden;
  }

  body section .parent_container .banner .banner_content {
    margin-bottom: 25px;
  }

  body section .parent_container .banner .banner_content .banner_mute {
    display: none;
  }

  body section .parent_container .banner .banner_content div:nth-child(2) {
    width: max(270px, 65%);
  }

  body section .parent_container .banner .banner_content div:nth-child(2) h1, body section .parent_container .banner .banner_content div:nth-child(2) p {
    text-align: left;
  }

  body section .parent_container .banner .banner_content div:nth-child(2) p {
    font-size: .7rem;
  }

  body section .parent_container .banner .banner_content div:nth-child(3) {
    font-size: .8rem;
  }

  body section .parent_container .main .latest_container {
    margin-top: 25px;
  }

  body section .parent_container .main .latest_container .last_night .flexbox {
    display: block;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details {
    width: 100%;
    height: max(520px, 30vh);
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description {
    bottom: 0;
    transform: translateY(0);
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description:first-child {
    align-items: center;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description p {
    color: #000;
    margin-top: 30px;
    font-size: .9rem;
    font-weight: 400;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description .mobile_caption {
    display: block;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description .mobile_caption p {
    color: #000;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description .mobile_caption h1 {
    margin-bottom: 10px;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description .mobile_caption h5 {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .poster1 {
    width: 100%;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .poster1 .flex2_trey {
    margin-top: 25px;
  }

  body section .parent_container .main .latest_container .last_night .flexbox .content, body section .parent_container .main .latest_container .latest_project {
    display: none;
  }

  body section .parent_container .portfolio_section .caption h1 {
    margin-top: 80px;
  }

  body section .parent_container .portfolio_section .portfolio_container {
    height: max(150em, 260dvh);
    margin: 10px auto;
  }

  body section .parent_container .portfolio_section .portfolio_container .portfolio_heading h1 {
    width: 100%;
    font-size: clamp(1.5rem, 2.5vw, 4.5rem);
  }

  body section .parent_container .portfolio_section .portfolio_container a .cards {
    width: 80%;
  }

  body section .parent_container .portfolio_section .portfolio_container a .cards:nth-child(2) {
    transform: translateY(0%, -5%);
    width: 60%;
    top: 10%;
    right: 0%;
  }

  body section .parent_container .portfolio_section .portfolio_container a .cards:nth-child(3) {
    transform: translateY(-3%, -25%);
    width: 65%;
    top: 40%;
    left: 23%;
  }

  body section .parent_container .portfolio_section .portfolio_container a .cards p {
    font-size: .7rem !important;
  }

  body section .parent_container .portfolio_section .portfolio_container a .cards:nth-child(4) {
    transform: translateY(-3%, -60%);
    width: 55%;
    top: 80%;
    right: 3%;
  }

  body section .parent_container .portfolio_section .portfolio_container a .cards:nth-child(5) {
    transform: translateY(-3%, -80%);
    width: 55%;
    top: 90%;
    right: 3%;
  }

  body section .parent_container .portfolio_section .portfolio_container a .card1 {
    top: 8%;
    transform: translateY(-8%);
  }

  body section .parent_container .portfolio_section .portfolio_container a .card2 {
    top: 28% !important;
    transform: translateY(-28%) !important;
  }

  body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards .cover {
    width: 365px;
    height: 274px;
  }

  body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .cover img {
    width: 74%;
  }

  body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(5) .cover img {
    width: 76%;
  }

  body section .parent_container .portfolio_section .portfolio_container #card3 {
    width: 90%;
    top: 63% !important;
    transform: translateY(-63%) !important;
  }

  body section .parent_container .portfolio_section .portfolio_container #card3 .flex1 {
    margin: 0;
  }

  body section .parent_container .portfolio_section .portfolio_container #card4 {
    top: 95% !important;
    transform: translateY(-95%) !important;
  }

  body section .parent_container .reels_section .reels_container {
    align-items: center;
    width: 90%;
    height: 70vh;
  }

  body section .parent_container .reels_section .reels_container .flex1, body section .parent_container .reels_section .reels_container .flex3 {
    display: none;
  }

  body section .parent_container .reels_section .reels_container .flex2 {
    flex-grow: 1;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel {
    position: relative;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .details {
    position: absolute;
    bottom: 15px;
    left: 20%;
    transform: translate(-20%);
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .details .profile_image {
    width: 20%;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .details .profile_image img {
    width: 100%;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .details p:nth-child(2) {
    font-size: .8rem;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .details p:nth-child(3) {
    width: 70%;
    font-size: .7rem;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .interactives {
    position: absolute;
    bottom: 25px;
    right: 15px;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .interactives .icon:first-child {
    width: 43px;
    margin: 15px 0;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .interactives .icon:first-child img {
    width: 100%;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .reel_heading {
    padding: 5%;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .reel_heading p {
    font-size: .7rem;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .reel_heading .socials {
    display: flex;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .reel_heading .socials .social2 {
    width: 42px;
  }

  body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .reel .overlay .reel_heading .socials .social2 img {
    width: 100%;
  }

  body section .parent_container .about_section .about {
    width: 70%;
  }

  body section .parent_container .about_section .about .poka_icon {
    margin: 20px 0;
  }

  body section .parent_container .about_section .about p {
    font-size: .9rem;
  }

  body section .parent_container footer .contact {
    display: block;
  }

  body section .parent_container footer .contact .flex {
    width: 100%;
  }

  body section .parent_container footer .contact .flex .contact_img {
    width: max(130px, 34%);
  }

  body section .parent_container footer .footer {
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;
  }

  body section .parent_container footer .footer .footer_content {
    display: block;
  }

  body section .parent_container footer .footer .footer_content .logo, body section .parent_container footer .footer .footer_content .location, body section .parent_container footer .footer .footer_content .social_media {
    margin-top: 50px;
  }

  body section .parent_container footer .footer .footer_content .logo p, body section .parent_container footer .footer .footer_content .location p, body section .parent_container footer .footer .footer_content .social_media p {
    margin: 5px 0;
    font-size: .9rem;
  }

  body section .parent_container footer .footer .footer_content .logo {
    width: 60%;
  }

  body section .parent_container footer .footer .footer_content .logo img {
    width: 100%;
  }

  body section .parent_container footer .footer .footer_content .logo p {
    font-size: .8rem;
  }

  body section .parent_container footer .footer .footer_content .location .location_container {
    align-items: flex-end;
  }

  body section .parent_container footer .footer .footer_content .location .location_container .link_icon {
    width: 15px;
  }

  body section .parent_container footer .footer .footer_content .location .location_container .link_icon img {
    width: 100%;
  }

  body section .parent_container footer .footer .footer_content .social_media ul {
    padding: 0;
  }

  body section .parent_container footer .footer .footer_content .social_media ul li .link_icon {
    width: 15px;
  }

  body section .parent_container footer .footer .footer_content .social_media ul li .link_icon img {
    width: 100%;
  }

  body section .parent_container .work_section .works_flexbox {
    flex-direction: column;
  }

  body section .parent_container .work_section .works_flexbox .gradient_background {
    display: none;
  }

  body section .parent_container .work_section .works_flexbox .works_container {
    width: 100%;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works {
    padding-bottom: 80px;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: auto;
    padding: 10px 0 0;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer {
    width: 95%;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .viz_img_container {
    height: max(230px, 29.5vh);
    margin-bottom: 20px;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .gallery_img_container {
    height: max(160px, 20vh);
    margin-bottom: 10px;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .gallery_img_container img {
    width: 99%;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .gallery_img_container .glow {
    height: inherit !important;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:nth-child(2) {
    align-items: flex-start;
  }

  body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container:first-child {
    padding-top: 100px;
  }

  body section .parent_container .work_section .works_flexbox .works_container #works {
    grid-template-columns: auto auto;
  }

  body section .parent_container .work_section .works_flexbox .works_container #works .vizualizer_container {
    height: auto;
  }

  body section .parent_container .work_section .works_flexbox #gallery_works_container {
    width: 95%;
    margin: 0 auto;
  }

  body section .parent_container .work_section .works_flexbox .side_details {
    width: 100%;
    display: none;
  }

  body section .parent_container .work_section .gradient_background {
    width: 100%;
  }

  body .reel_parent {
    border: 2px solid red;
    width: 100%;
    height: 100dvh;
    overflow: auto;
  }

  body .reel_parent .reel_trey {
    flex-direction: column;
    height: max-content;
    display: flex;
  }

  body .reel_parent .reel_trey .reels {
    background-color: orange;
    width: 100%;
    height: 100dvh;
  }

  body .reel_parent .reel_trey .reels:nth-child(2) {
    background-color: #ff4500;
  }
}

@media screen and (width <= 390px) {
  body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details {
    height: max(570px, 30vh);
  }

  body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards .cover {
    width: 315px;
  }

  body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .cover img, body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(5) .cover img {
    width: 65%;
  }
}
/*# sourceMappingURL=index.ad984f09.css.map */
